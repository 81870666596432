<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" width="40" height="32" />
        <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Connexion" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Bienvenu 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Veuillez vous connecter à votre compte.
          </b-card-text>

          <br />

          <b-alert variant="primary" show>
            <!-- <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div> -->
            <!-- <feather-icon
              v-b-tooltip.hover.left="'Votre compte doit avoir les accèss'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            /> -->
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="auth">
              <!-- email -->
              <b-form-group label="Email / Compte" label-for="account">
                <validation-provider #default="{ errors }" vid="account" rules="required" name="account">
                  <!-- vid="email"
                  rules="required|email" -->

                  <b-form-input id="account" v-model="account" :state="errors.length > 0 ? false : null" name="account"
                    placeholder="Votre compte ici" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password // -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Mot de passe oublié?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="Votre Mot de passe" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- refreshSystem -->
              <b-form-group v-if="refreshSystemShow == true || status == true">
                <b-form-checkbox id="refreshSystem" v-model="refreshSystem" name="refreshSystem">
                  Actualiser le sysème
                  <span class="text-success">(Mise à jour)</span>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="loading">
                <LoadingIcon v-if="loading" style="width: 24px; float: center"></LoadingIcon>

                Se connecter
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Vous etes nouveau ? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Créer un compte</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import LoadingIcon from "@core/layouts/components/Loading.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import navItems from '@/navigation/vertical'
import { $themeConfig } from "@themeConfig";
import axios from "axios";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    Logo,
    ValidationProvider,
    ValidationObserver,
    LoadingIcon,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage, api_endpoint } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
      api_endpoint,
      LoadingIcon,
    };
  },
  data() {
    return {
      status: "",
      account: "", //admin@demo.com
      password: "", //admin
      sideImg: require("@/assets/images/pages/login.png"),
      //
      loading: false,
      refreshSystem: false,
      refreshSystemShow: false,

      // validation rules
      required,
      email,
      contacts: Array,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    // recuperer autorisation globales
    //
    // this.getPermissions("admin");
  },
  mounted() {
    var last_route_name = localStorage.getItem("last_route_name");
    if (
      last_route_name != "" &&
      last_route_name != null &&
      last_route_name != undefined
    ) {
      // s il ya de route en attente
      this.refreshSystemShow = true;
    }

    // recuperation des donnees
    if (localStorage.getItem("menus") == null) {
      // online get
      // get menus
      // this.getMenus();
    } else {
      if (this.refreshSystem == true) {
        // refresh menu
        // this.getMenus();
      } else {
        this.loading = false;
      }
    }
  },
  methods: {

    async AddMenusAndRoutes(mode) {

      // Same as above
      function isset(ref) {
        return typeof ref !== "undefined";
      }

      const dataStored = localStorage.getItem("userData");
      // ---------------------------------------------------------------------------
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.departmentData != null && userData.departmentData != undefined) {
        const departmentsPermissions = userData.departmentData;
        var routesFormated = this.$router.options.routes;

        for (let index = 0; index < departmentsPermissions.length; index++) {
          // const
          const department = departmentsPermissions[index];
          const modules = departmentsPermissions[index].modules;
          const departmentRoute = department.route;

          // add menus
          try {
            // Do something that might trigger an error

            if (department.route == "human-ressource") {
              // add separator
              navItems.push({
                header: "Modules Principaux",
                action: "read",
                resource: "ACL",
              });
            } else if (department.route == "data-collect") {
              // add separator
              navItems.push({
                header: "Autres Outils",
                action: "read",
                resource: "ACL",
              });
            }

            var modulesFormated = [];
            for (let module_index = 0; module_index < modules.length; module_index++) {
              const module = modules[module_index];
              if (module.module != "") {
                modulesFormated.push({
                  title: module.module,
                  route: module.ressource,
                  // acl: {
                  action: "read",
                  resource: module.ressource,
                  // },
                });
              }
            }

            //
            if (department.name != "") {
              navItems.push({
                title: department.name,
                icon: department.icon,
                // acl: {
                action: "read",
                resource: department.route,
                // },
                // tag: '2',
                tagVariant: "light-warning",
                children: modulesFormated,
              });
            }

          } catch (error) {
            // Only runs when there is an error/exception
          } finally {
            // Code here always runs. Doesn't matter if there was an error or not.
          }

          // add routes
          for (
            let module_index = 0;
            module_index < modules.length;
            module_index++
          ) {
            const module = modules[module_index];

            const departmentName =
              department.name.charAt(0).toLowerCase() +
              department.name.substring(1, department.name.length).toLowerCase();

            const moduleName =
              module.ressource.charAt(0).toUpperCase() +
              module.ressource.substring(1, module.ressource.length).toLowerCase();

            let moduleFolder = "";
            if (module.folder == true) {
              moduleFolder = module.ressource + "/";
            }

            if (module.ressource != null && module.module != null) {
              if (module.folder == true) {
                // check permissions
                if (isset(module.list) && module.list == true) {
                  routesFormated.push(
                    {
                      path: "/" + departmentRoute + "/" + module.ressource,
                      name: module.ressource,
                      component: () =>
                        import(
                          "@/views/" +
                          departmentRoute +
                          "/" +
                          moduleFolder +
                          "Index.vue"
                        ),
                      meta: {
                        resource: module.ressource,
                        pageTitle: module.module,
                        pageIcon: department.icon,
                      },
                    },
                    {
                      path: "/" + departmentRoute + "/" + module.ressource,
                      name: module.ressource + "-list",
                      component: () =>
                        import(
                          "@/views/" +
                          departmentRoute +
                          "/" +
                          moduleFolder +
                          "Index.vue"
                        ),
                      meta: {
                        resource: module.ressource,
                        pageTitle: module.module,
                        pageIcon: department.icon,
                      },
                    }
                  );
                }
                if (isset(module.add) && module.add == true) {
                  routesFormated.push({
                    path:
                      "/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/" +
                      module.ressource +
                      "-add-new",
                    name: module.ressource + "-add-new",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: department.name,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      //}
                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Ajouter",
                          active: true,
                        },
                      ],
                    },
                  });
                }
                if (isset(module.view) && module.view == true) {
                  routesFormated.push({
                    path:
                      "/" + departmentRoute + "/" + module.ressource + "-view/:id",
                    name: module.ressource + "-view",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: departmentName,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Infos " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });
                }
                if (isset(module.update) && module.update == true) {
                  routesFormated.push({
                    path:
                      "/" + departmentRoute + "/" + module.ressource + "-edit/:id",
                    name: "" + module.ressource + "-edit",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: department.name,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Modifier " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });

                  // check if accounting
                  if (departmentRoute == 'accounting') {

                    routesFormated.push({
                      path: "/" + departmentRoute + "/" + module.ressource + "-param/:id",
                      name: "" + module.ressource + "-param",
                      params: { searchResults: "testResult" },
                      component: () =>
                        import(
                          "@/views/" +
                          departmentRoute +
                          "/" +
                          module.ressource +
                          "/Configuration.vue"
                        ),
                      meta: {
                        pageTitle: department.name,
                        pageIcon: department.icon,
                        //{ acl
                        resource: module.ressource,
                        action: "read",
                        //}

                        contentClass: module.ressource,
                        navActiveLink: module.ressource,

                        breadcrumb: [
                          {
                            text: moduleName + "s",
                          },
                          {
                            text: "Parametrer " + moduleName,
                            active: true,
                          },
                        ],
                      },
                    }
                    );

                  }

                }
                if (isset(module.delete) && module.delete == true) {
                  // routesFormated.push();
                }
                if (isset(module.monitoring) && module.monitoring == true) {
                  routesFormated.push({
                    path:
                      "/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "-monitoring/:id",
                    name: module.ressource + "-monitoring",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Monitoring.vue"
                      ),
                    meta: {
                      pageTitle: departmentName,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Suivi " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });
                }
                // add action routes end
              } else {
                // module without folder
                if (isset(module.view) && module.view == true) {
                  routesFormated.push({
                    path: "/" + departmentRoute + "/" + module.ressource,
                    name: module.ressource,
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        moduleFolder +
                        moduleName +
                        ".vue"
                      ),
                    meta: {
                      pageTitle: module.module,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                    },
                  });
                }
              }
            }
          }
        }

        this.$router.addRoutes(routesFormated)

      } else {
        // no userData
      }

    },
    async login(online) {
      var userOnline = window.navigator.onLine ? "on" : "off";
      this.loading = true;
      var maintenance_mode = true;

      if (online == 1) {
        // online login is required
        if (maintenance_mode == false) {
          if (userOnline == "off") {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-center",
              props: {
                title: `Erreur Réseau`,
                icon: "InfoIcon",
                variant: "warning",
                text: "Vérifier votre connectivité Internet",
              },
            });
            this.loading = false;
            return;
          }
        }

        let formData = new FormData();
        formData.append("email", this.account);
        formData.append("password", this.password);

        await axios
          .post($themeConfig.app.api_endpoint + `auth/login`, formData)
          .then((response) => {
            var userData = response.data.data;

            var accessToken = response.data.access_token;
            var accessTokenType = response.data.token_type;

            $themeConfig.app.api_endpoint_access_token = accessToken;
            $themeConfig.app.refresh_required = true;

            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("token", accessToken);

            // initialisation
            if (this.$router.options.routes.length <= 82) {
              this.AddMenusAndRoutes();
            }

            // ok
            if (userData.role != localStorage.getItem("lastuserrole")) {
              // user has change role
              // reload
              localStorage.removeItem("lastuserrole")
              window.location = "/dashboard/default";
            }

            /* console.log("userData.role")
            console.log(userData.role)
            console.log("currentUserData.role")
            console.log(localStorage.getItem("lastuserrole")) */

            this.loading = false;

            this.login(2);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-center",
              props: {
                title: `Erreur Survenu`,
                icon: "AlertIcon",
                variant: "warning",
                text: "Vérifier votre compte/email et votre mot de passe",
              },
            });
          });
      } else {
        // offline

        useJwt
          .login({
            email: this.account,
            password: this.password,
          })
          .then((response) => {
            this.loading = false;

            let token = localStorage.getItem("token");
            if (token != null && token != undefined) {
              const { userData } = response.data;
              useJwt.setToken(userData.accessToken);
              useJwt.setRefreshToken(userData.refreshToken);
              // store user permission on the instance
              localStorage.setItem("userData", JSON.stringify(userData));

              // update api config
              myApi.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${localStorage.getItem("token")}`;

              // permissions
              this.$ability.update(userData.ability);

              $themeConfig.app.userPermissions = userData.special_permissions;
              window.vueSpatiePermissions = userData.special_permissions;

              // get permissions
              console.log("uswindow.Laravel");
              localStorage.setItem(
                "roles-permissions",
                window.vueSpatiePermissions
              );
              console.log(window.vueSpatiePermissions);
              console.log("window.Laravel end");

            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-center",
                props: {
                  title: `Erreur Survenu`,
                  icon: "InfoIcon",
                  variant: "warning",
                  text: response.message, // error.response.data.error
                },
              });
            }

            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            /* this.$store.commit(
              "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
              userData.extras.eCommerceCartItemsCount
            ); */

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            if ($themeConfig.app.refresh_required == true) {
              // this.$router.push({ name: "dashboard-default" }); 
              console.log("refresh required")

              var last_route_name = localStorage.getItem("last_route_name");
              if (
                last_route_name != "" &&
                last_route_name != null &&
                last_route_name != undefined
              ) {
                localStorage.removeItem("last_route_name");
                // window.location.href = last_route_name;
                this.$router.push({ name: "dashboard-default" });

                /* if (this.refreshSystem == true) {
                  window.location.href = last_route_name;
                } else {
                  this.$router.push({ path: last_route_name });
                } */
              } else {
                // window.location.href = "/";
                /* if (this.refreshSystem == true) {
                  window.location.href = "/";
                } else {
                  this.$router.push({ name: "dashboard-default" });
                } */
                this.$router.push({ name: "dashboard-default" });
              }
            } else {
              console.log("no refresh required")
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-center",
                    props: {
                      title: `Bienvenu ${userData.fullName || userData.username
                        }`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Vous vous êtes connecté avec succès en tant que ${userData.role}. Vous pouvez maintenant commencer à explorer !`,
                    },
                  });
                });
              /* var lastUrlName = localStorage.getItem("last_route_name");
              if (lastUrlName.length>0) {
                // redirect to last url
                localStorage.removeItem("last_route_name");
                router.push({ path: lastUrlName });
              } else {
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-center",
                      props: {
                        title: `Bienvenu ${
                          userData.fullName || userData.username
                        }`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Vous vous êtes connecté avec succès en tant que ${userData.role}. Vous pouvez maintenant commencer à explorer !`,
                      },
                    });
                  });
              } */
            }
          })
          .catch((error) => {
            // this.$refs.loginForm.setErrors(error.response.data.error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-center",
              props: {
                title: `Erreur d'Authentification`,
                icon: "InfoIcon",
                variant: "warning",
                text: error.response.data.error, // error.response.data.error
              },
            });
          });
      }
    },
    auth() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          if (
            // offline
            localStorage.getItem("userData") == null ||
            localStorage.getItem("userData") == undefined
          ) {
            this.login(1);
          } else {
            // online login
            this.login(2);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
